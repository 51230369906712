import {
  usePendingSurveyServiceQuery,
  useSurveyAvailabilitiesQuery,
} from "__generated__/types";
import { useOrder } from "services/orders";

/**
 * Utility hook that combines availabilities and survey service queries as
 * the date range for the availabilities request is dependent on the survey service
 * @returns apollo query params for both queries
 */
export const useSurveyAvailabilities = () => {
  const order = useOrder();
  const timezone = order.originTimeZone;

  const { data: surveyServiceData, loading: surveyServiceLoading } =
    usePendingSurveyServiceQuery({
      variables: { orderId: order.id! },
      skip: !order,
    });

  const {
    data: surveyAvailabilitiesData,
    loading: surveyAvailabilitiesLoading,
    refetch,
  } = useSurveyAvailabilitiesQuery({
    variables: {
      orderId: order.id!,
      startDate: surveyServiceData?.pendingSurveyService?.minSurveyDate || "",
      endDate: surveyServiceData?.pendingSurveyService?.maxSurveyDate || "",
      surveyType: "VIRTUAL",
      timezone,
    },
    skip: !order || !surveyServiceData?.pendingSurveyService || !timezone,
  });

  const loading = surveyAvailabilitiesLoading || surveyServiceLoading;

  return {
    loading,
    surveyServiceLoading,
    surveyAvailabilitiesLoading,
    surveyAvailabilitiesData,
    surveyServiceData,
    refetch,
    timezone,
  };
};
