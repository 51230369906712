"use client";
import { useState } from "react";
import { groupBy } from "lodash";
import styled from "@emotion/styled";
import {
  Box,
  Tab as MuiTab,
  Tabs as MuiTabs,
  Stack,
  Typography,
} from "@mui/material";
import { RoomCard } from "./";
import { useSurveyOrders } from "../tools/surveyAtom";
import { TabPanel } from "components/common/layouts/InventoryPageLayout/components/InventoryDisplay/components";
import { FormattedMessage, useIntl } from "services";
import Link from "next/link";

const Tab = styled(MuiTab)({
  maxWidth: "33%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "inline-block",
  "@media (max-width: 500px)": {
    fontSize: "14px",
  },
});

const Tabs = styled(MuiTabs)({
  "& .MuiTabs-flexContainer": {
    borderBottom: "1px solid #01314A",
  },
  "& .Mui-selected": {
    border: "1px solid #01314A",
    borderRadius: "8px 8px 0 0",
    borderBottom: "none",
  },
});

const InlineLink = styled(Link)({
  display: "inline",
});

const TabContentContainer = styled.article`
  padding: 30px;
  border: 1px solid #01314a;
  border-radius: 0 0 8px;
  border-top: none;
`;

export function SurveyItemSummary({
  selectedOrder,
}: {
  selectedOrder: string;
}) {
  const { formatMessage } = useIntl();
  const orders = useSurveyOrders();
  const [currentOrder, setCurrentOrder] = useState(
    selectedOrder || Object.values(orders)[0][0].order.number
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentOrder(newValue);
  };
  return (
    <>
      <Tabs
        value={currentOrder}
        onChange={handleTabChange}
        variant="fullWidth"
        TabIndicatorProps={{
          style: {
            backgroundColor: "white",
            transition: "none",
            border: "1px solid #01314A",
            borderBottom: "none",
            borderTop: "none",
          },
        }}
      >
        {Object.values(orders).map((segments) => {
          const { shipmentTypeLabel, number: orderNumber } = segments[0].order;
          return (
            <Tab
              key={`tab-${orderNumber}`}
              value={orderNumber}
              label={
                <>
                  {shipmentTypeLabel}
                  <Typography fontSize={"14px"}>
                    Order #{orderNumber}
                  </Typography>
                </>
              }
            ></Tab>
          );
        })}
      </Tabs>
      <TabContentContainer>
        {Object.values(orders!).map((segments) => {
          const { number: orderNumber, isShipmentSurveyLocked: isLocked } =
            segments[0].order;
          const rooms = groupBy(
            segments.flatMap((segment) => segment.items),
            (item) => item.roomId
          );
          return (
            <TabPanel
              value={currentOrder}
              index={orderNumber}
              key={`tab-content-${orderNumber}`}
            >
              {isLocked && (
                <Box mb="20px">
                  <FormattedMessage
                    id="reviewYourBelongings.shipmentLocked"
                    values={{
                      link: (
                        <InlineLink href="/support">
                          <FormattedMessage id="reviewYourBelongings.contactLink" />
                        </InlineLink>
                      ),
                    }}
                  />
                </Box>
              )}
              <Stack
                aria-label={formatMessage({
                  id: "ariaLabels.surveyByRoom",
                })}
              >
                {Object.keys(rooms!).map((id) => {
                  const name = rooms?.[id][0].room.roomName;
                  return (
                    id && (
                      <RoomCard
                        name={name ?? ""}
                        key={`room-${id}-${orderNumber}`}
                        roomId={id}
                        orderNumber={orderNumber}
                        isLocked={isLocked}
                      />
                    )
                  );
                })}
              </Stack>
            </TabPanel>
          );
        })}
      </TabContentContainer>
    </>
  );
}
