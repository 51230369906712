import { datadogRum } from "@datadog/browser-rum";
import { CheckBoxOutlineBlank } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import {
  useAcknowledgeExcessCostMutation,
  useGetOrderByIdQuery,
} from "__generated__/types";
import classnames from "classnames";
import { formatPriceUsd } from "components/common/layouts/InventoryPageLayout/components/ClaimsDisplay/ClaimsList/moneyConversions";
import { useSurveyCalculations } from "hooks";
import { useEstimatedMoveCost } from "hooks/useEstimatedOverageCost";
import { Order } from "models";
import { FormattedMessage, useIntl } from "services";
import { useFlags } from "services/launch-darkly";
import { useNotify } from "services/notification";
import scss from "../Belongings.module.scss";

interface Props {
  mode?: "SURVEY" | "ADJUSTED_SURVEY";
  orderId?: Order["id"];
}

export const CostSummary: React.FC<Props> = (
  { mode, orderId } = { mode: "SURVEY" }
) => {
  const { formatMessage } = useIntl();
  const {
    hasOverage: hasWeightOverage,
    overage,
    estimatedWeight,
    estimatedWeightBreakDown,
  } = useSurveyCalculations(mode === "ADJUSTED_SURVEY");

  const { error, data, loading } = useEstimatedMoveCost({
    estimatedWeightBreakDown: estimatedWeightBreakDown,
  });

  const notify = useNotify();
  const flags = useFlags();

  const [acknowledgeExcessCost, { loading: ackLoading }] =
    useAcknowledgeExcessCostMutation({
      onError: (error) => {
        notify("Error acknowledging excess cost. Please try again later.", {
          variant: "error",
        });

        datadogRum.addError(error);
      },
    });

  const { data: orderData } = useGetOrderByIdQuery({
    variables: { id: orderId! },
    skip: !orderId || !flags.showExcessCostAcknowledgement,
  });

  const acknowledged =
    !!orderData?.order?.moveTaskOrder?.excessCostAcknowledgement
      ?.acknowledgedAt;

  if (loading) {
    return (
      <Box borderRadius={"8px"} border="1px solid #DDD" padding="16px 0px">
        <CircularProgress />
        <div>
          <Typography align="center"> Calculating move costs...</Typography>
        </div>
      </Box>
    );
  }

  // We can't calculate costs for weights less than 500 lbs or greater than 1,000,000 lbs
  if (estimatedWeight < 500 || estimatedWeight > 1000000) {
    return null;
  }

  if (!data || error) {
    return (
      <Box>
        <Alert severity="error">Error calculating costs</Alert>
      </Box>
    );
  }

  const hasCostOverage = data.costEstimate.excessCost > 0;
  const showExcessCostAcknowledgement =
    flags.showExcessCostAcknowledgement &&
    // safeguard for orders that were in progress when feature was introduced
    !!orderData?.order?.moveTaskOrder?.excessCostAcknowledgement;

  return hasCostOverage ? (
    <Box>
      <div className={classnames(scss.overage)}>
        <div
          className={classnames(scss.overage_warning)}
          aria-label={formatMessage({
            id: "ariaLabels.entitlementOverageWarning",
          })}
        >
          <ErrorOutlineIcon />
          <div>
            <div>
              {hasWeightOverage ? (
                <FormattedMessage id="gauge.alert.exceedsEntitlement" />
              ) : (
                // If there is no weight overage, we assume that the cost average
                // must have occured due to an address change
                <FormattedMessage id="guage.alert.extraCostWithinEntitlement" />
              )}
            </div>

            <Stack>
              <strong>
                <FormattedMessage
                  id="gauge.overage"
                  values={{
                    overage,
                    estimatedCost: `${formatPriceUsd(
                      data.costEstimate.excessCost
                    )}`,
                  }}
                />
              </strong>
              {mode === "SURVEY" && showExcessCostAcknowledgement && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={acknowledged}
                        sx={{ paddingRight: "0px" }}
                        onChange={(event) => {
                          const moveTaskOrderId =
                            orderData?.order?.moveTaskOrder?.id;
                          if (event.target.checked && moveTaskOrderId) {
                            acknowledgeExcessCost({
                              variables: {
                                ackExcessCostInput: {
                                  moveTaskOrderId,
                                },
                              },
                            });
                          }
                        }}
                        disabled={ackLoading || acknowledged}
                        icon={
                          ackLoading ? (
                            <CircularProgress
                              className={classnames(scss.loading)}
                              size={"14px"}
                              style={{ marginRight: "16px" }}
                            />
                          ) : (
                            <CheckBoxOutlineBlank />
                          )
                        }
                      />
                    }
                    label={
                      <FormattedMessage id="guage.message.excessCostAcknowledgement" />
                    }
                  />
                </FormGroup>
              )}
            </Stack>
            {!ackLoading && <span className={classnames(scss.loading)}></span>}
            {hasWeightOverage &&
              data.costEstimate.failedToIncludeOconusFuelSurcharge && (
                <div>
                  <Typography variant="xsBody">
                    <FormattedMessage id="gauge.alert.fuelChargeDisclaimer" />
                  </Typography>
                </div>
              )}
          </div>
        </div>
      </div>
    </Box>
  ) : (
    <div className={classnames(scss.within_entitlement)}>
      <div
        className={classnames(scss.within_entitlement_content)}
        aria-label={formatMessage({
          id: "ariaLabels.withinEntitlementMessage",
        })}
      >
        <CheckCircleOutlineIcon />
        {hasWeightOverage ? (
          <FormattedMessage id="gauge.alert.exceedsEntitlementNoExtra" />
        ) : (
          <FormattedMessage id="gauge.alert.withinEntitlement" />
        )}
      </div>
    </div>
  );
};
