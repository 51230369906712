import { useState } from "react";
import { InfoBox } from "components/common/InfoBox";
import { Box, Popover } from "@mui/material";
import { FormattedMessage, translationKeys } from "services";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { colors } from "styles";

interface InfoPopoverProps {
  titleMessageId?: translationKeys;
  contentMessageId: translationKeys;
}

/**
 * Renders an (i) icon and a information popover
 * when the user hovers over the icon.
 * @param param0
 * @returns
 */
export const InfoPopover: React.FC<InfoPopoverProps> = ({
  titleMessageId,
  contentMessageId,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div onMouseOver={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        <HelpOutlineOutlinedIcon style={{ color: colors.linkBlue }} />
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          pointerEvents: "none",
        }}
        hideBackdrop
      >
        <Box maxWidth="300px">
          <InfoBox
            title={
              titleMessageId ? <FormattedMessage id={titleMessageId} /> : ""
            }
            content={<FormattedMessage id={contentMessageId} />}
          ></InfoBox>
        </Box>
      </Popover>
    </>
  );
};
