import { useGetCostEstimateQuery } from "__generated__/types";
import { useSurvey } from "components/pages/BelongingsPage/tools/surveyAtom";
import { depotHost } from "constant/apiEndpoints";
import useSWR from "swr";
import { postFetcher } from "tools/postFetcher";
import { ShipmentWeightBreakdowns } from "types";

export function useEstimatedOverageCost({
  hasOverage,
  totalWeight,
}: {
  hasOverage: boolean;
  totalWeight: number;
}) {
  const survey = useSurvey();
  const url = `${depotHost}/api/v1/surveys/${survey?.id}/estimated_overage_cost`;
  return useSWR(
    survey?.id &&
      hasOverage && [
        url,
        {
          body: {
            total_weight: totalWeight,
          },
        },
      ],
    postFetcher
  );
}

export function useEstimatedMoveCost(params: { estimatedWeightBreakDown: ShipmentWeightBreakdowns }) {
  const breakdowns = Object.entries(params.estimatedWeightBreakDown).map(
    ([_, { weight, orderId }]) => {
      return {
        orderId,
        adjustedWeight: weight,
      };
    }
  );
  const { error, data, loading } = useGetCostEstimateQuery({ variables: {input: breakdowns}});
  return {
    data,
    loading,
    error,
  };
}
